import {useEffect, useState} from "react";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import Fade from "@mui/material/Fade";
import Badge from "@mui/material/Badge";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import styled from "@mui/material/styles/styled";
import IconButton from "@mui/material/IconButton";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Notifications from "@mui/icons-material/Notifications";
import {formatDistance} from "date-fns";

import {FlexBox} from "@/components/flex-box";
import {H6, Paragraph} from "@/components/Typography";
import {router, usePage} from "@inertiajs/react";
import {NotificationsNone} from "@mui/icons-material";
import Delete from "@mui/icons-material/Delete";

// styled components

const StyledTabList = styled(TabList)(({
                                           theme
                                       }) => ({
    "& .MuiTab-root": {
        textTransform: "capitalize"
    },
    "& .MuiTab-root.Mui-selected": {
        color: theme.palette.info.main
    },
    "& .MuiTabs-indicator": {
        backgroundColor: theme.palette.info.main
    }
}));
const StyledTab = styled(Tab)({
    width: "50%",
    marginLeft: 0,
    marginRight: 0
});
const ListItemWrapper = styled(FlexBox)(({
                                             theme
                                         }) => ({
    cursor: "pointer",
    borderBottom: `1px solid ${theme.palette.info[100]}`,
    ":hover": {
        backgroundColor: theme.palette.info[100]
    },
    ":last-of-type": {
        borderBottom: 0
    }
}));
export default function NotificationsPopover() {
    const {notifications, auth: {user}} = usePage().props
    const [open, setOpen] = useState(false);
    const [tabValue, setTabValue] = useState("1");
    const [anchorEl, setAnchorEl] = useState(null);
    const mapNotification = notification => ({
        id: notification.id,
        createdAt: new Date(notification.created_at),
        title: notification.content,
        type: notification.type,
        icon: NotificationsNone,
        isRead: notification['is_read'],
    })

    const [viewed, setViewed] = useState(false);
    const [notificationsArray, setNotificationsArray] = useState(notifications.filter(notification => !notification['archived']).map(mapNotification));
    const [archivedNotificationsArray, setArchivedNotificationsArray] = useState(notifications.filter(notification => notification['archived']).map(mapNotification));
    const handleClick = event => {
        setViewed(true);
        setOpen(state => !state);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(false);
        setViewed(false);
        setAnchorEl(null);
    };

    const handleTabChange = (_, value) => {
        setTabValue(value);
    };

    const handleDelete = (id, type) => {
        if (type === 'archived') {
            setArchivedNotificationsArray(archivedNotificationsArray.filter(notification => notification.id !== id));
        } else {
            setNotificationsArray(notificationsArray.filter(notification => notification.id !== id));
        }
        router.delete('/notifications/' + id, {
            preserveState: true,
            preserveScroll: true,
            onSuccess: () => {
                console.log('success')
            }
        });
    };

    useEffect(() => {
        if (viewed) {
            const timer = setTimeout(() => {
                let unreadIds = [];
                if (tabValue === "1") {
                    unreadIds = notificationsArray.filter(notification => !notification.isRead).map(notification => notification.id);
                    setNotificationsArray(state => [...state.map(notification => ({
                        ...notification,
                        isRead: true
                    }))]);
                } else if (tabValue === "2") {
                    unreadIds = archivedNotificationsArray.filter(notification => !notification.isRead).map(notification => notification.id);
                    setArchivedNotificationsArray(state => [...state.map(notification => ({
                        ...notification,
                        isRead: true
                    }))]);
                }
                if (unreadIds.length > 0) {
                    router.post('/notifications/mark-read', {
                            ids: unreadIds
                        },
                        {
                            preserveState: true,
                            preserveScroll: true,
                            onSuccess: () => {
                                console.log('success')
                            }
                        });
                }
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [tabValue, viewed]);

    useEffect(() => {
        if (user?.id) {
            const channel = window.Echo.private(`notifications.${user.id}`);
            // Listen for the event with the correct namespace
            channel.listen('.PushNotification', e => {
                const notification = e?.notification;
                setNotificationsArray(state => [mapNotification(notification), ...state]);
            });

            return () => {
                channel.stopListening('.PushNotification');
                channel.unsubscribe();
            };
        }
    }, []);

    return <ClickAwayListener onClickAway={handleClose}>
        <div>
            <IconButton onClick={handleClick}>
                <Badge color="secondary" variant="dot" badgeContent={notificationsArray.filter(n => !n.isRead).length}>
                    <Notifications sx={{
                        color: "grey.500"
                    }}/>
                </Badge>
            </IconButton>

            <Popper transition open={open} anchorEl={anchorEl} placement="bottom-end" sx={{
                zIndex: 11111,
                maxWidth: 300,
                minWidth: 300,
                width: "100%",
                top: "10px !important",
                boxShadow: 2,
                "&:before": {
                    top: 0,
                    right: 14,
                    zIndex: 0,
                    width: 10,
                    height: 10,
                    content: '""',
                    display: "block",
                    position: "absolute",
                    borderTop: "1px solid",
                    borderLeft: "1px solid",
                    borderColor: "grey.200",
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)"
                }
            }}>
                {({
                      TransitionProps
                  }) => <Fade {...TransitionProps} timeout={150}>
                    <Paper>
                        <TabContext value={tabValue}>
                            <StyledTabList onChange={handleTabChange}>
                                <StyledTab disableRipple value="1"
                                           label={`Unread (${notificationsArray.filter(n => !n.isRead).length})`}/>
                                <StyledTab disableRipple value="2" label="Archived"/>
                            </StyledTabList>

                            {notificationsArray.length === 0 && tabValue === "1" ?
                                <Paragraph fontWeight="500" textAlign="center" p={2}>
                                    There are no notifications
                                </Paragraph> : <TabPanel value="1" sx={{
                                    p: 0
                                }}>
                                    {notificationsArray.map(notification => <ListItem key={notification.id}
                                                                                      type={notification.type}
                                                                                      Icon={notification.icon}
                                                                                      title={notification.title}
                                                                                      createdAt={notification.createdAt}
                                                                                      isRead={notification.isRead}
                                                                                      handleDelete={() => handleDelete(notification.id, 'unread')}/>)}
                                </TabPanel>}

                            {archivedNotificationsArray.length === 0 && tabValue === "2" ?
                                <Paragraph fontWeight="500" textAlign="center" p={2}>
                                    There are no archives
                                </Paragraph> : <TabPanel value="2" sx={{
                                    p: 0
                                }}>
                                    {archivedNotificationsArray.map(item => <ListItem key={item.id} type={item.type}
                                                                                      Icon={item.icon}
                                                                                      title={item.title}
                                                                                      createdAt={item.createdAt}
                                                                                      isRead={notification.isRead}
                                                                                      handleDelete={() => handleDelete(item.id, 'archived')}/>)}
                                </TabPanel>}
                        </TabContext>
                    </Paper>
                </Fade>}
            </Popper>
        </div>
    </ClickAwayListener>;
}

// ListItem component props

function ListItem(props) {
    const {
        Icon,
        title,
        createdAt,
        handleDelete,
        isRead,
    } = props;
    return <ListItemWrapper p={2} gap={2} alignItems="center">
        <Icon color="info"/>
        <div style={{opacity: isRead ? 0.6 : 1}}>
            <H6 fontSize={13}>{title}</H6>
            <Paragraph fontSize={11}>
                {formatDistance(createdAt, new Date(), {
                    addSuffix: true
                })}
            </Paragraph>
        </div>
        <IconButton onClick={handleDelete} sx={{ml: 'auto'}}>
            <Delete fontSize="small"/>
        </IconButton>
    </ListItemWrapper>;
}
